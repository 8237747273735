import React from 'react';

const style = require('./PerkItem.module.scss');

type PerkItemProps = {
  title: string;
  text: string;
  className?: string;
  [otherProps: string]: any;
};

const PerkItem = (props: PerkItemProps) => {
  const { title, text, icon, className, ...rest } = props;

  return (
    <div {...rest} className={`${style.perkItem} ${className}`}>
      <div className={style.iconWrapper}>{icon}</div>
      <div className={style.textWrapper}>
        <h4>{title}</h4>
        <p className={style.text}>{text}</p>
      </div>
    </div>
  );
};

PerkItem.defaultProps = {
  className: '',
};

export default PerkItem;
